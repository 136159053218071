import React from "react"
import { Box, AspectRatio, Image } from "@chakra-ui/react"

const ProjektzielTeaserImage = ({ image, projektziel }) => {
  return (
    <Box
      display="flex"
      overflow="hidden"
      marginLeft={projektziel ? ["0px", "0px", "-128px", "-128px"] : "0px"}
      maxHeight={["640px"]}
      borderRadius="30px"
      alignItems="center"
      justifyContent="center"
      mb="40px"
    >
      <AspectRatio width="100%" ratio={22 / 10}>
        <Image
          src={image?.localFile.childImageSharp.fluid.src}
          srcSet={image?.localFile.childImageSharp.fluid.srcSet}
          alt={image?.altText}
        />
      </AspectRatio>
    </Box>
  )
}

export default ProjektzielTeaserImage
